import de from "./locales/de-DE.json";
import en from "./locales/en-GB.json";

// You can use `defineI18nConfig` to get type inferences for options to pass to vue-i18n.
export default defineI18nConfig(() => {
  return {
    legacy: false,
    locale: "de",
    messages: {
      en,
      de,
    },
  };
});
